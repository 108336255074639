@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

html,
body,
#root {
    width: 100%;
    min-height: 100%;
    height: 100%;
    // overflow: hidden;
}

.carousel_slide_number {
    position: absolute;
    bottom: 0;
    right: 39.5% !important;
    bottom: 5% !important;
}

.carousel .thumbs-wrapper {
    margin: 0px !important;
    overflow: hidden;
}

.control-dots {
    width: auto !important;
    right: 10% !important;
}

.carousel .control-dots {
    bottom: 5% !important;
}

.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3 !important;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    border-radius: 7px !important;
    background: #fff;
    width: 40px !important;
    height: 2px !important;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.carousel .control-dots .dot.selected {
    opacity: 1 !important;
}

.slider_active_number {
    font-size: 31px;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    border: 0 !important;
    content: url(../img/slider_arrow.svg) !important;
}

.carousel .control-prev.control-arrow:before {
    border: 0 !important;
    content: url(../img/slider_arrow_left.svg) !important;
}

/* .carousel .control-disabled.control-arrow {
    display: block !important;
    opacity: 1 !important;
} */

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    /* bottom: 20px; */
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
    bottom: 5% !important
}

.carousel .control-next.control-arrow {
    top: 89% !important;
    right: 35% !important;
}

.carousel .control-prev.control-arrow {
    top: 89% !important;
    left: 50% !important;
    width: 30px !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
}

.header-gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }

.competency_img {
    width: 40px;
}

.competency_section_height {
    min-height: 150px;
}

.compentency_name {
    font-size: 18px;
}

.watch_video {
    font-size: 13px;
}

.carousel_slide,
.carousel-root {
    // position: absolute !important;
    top: 0 !important;
    z-index: 99999 !important;
    width: 100% !important;
    height: 100% !important;
}

.carousel.carousel-slider, .slide, slider-wrapper {
    width: 100%;
    height: 100% !important;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    height: 100%;
}

.carousel .slider-wrapper{
    overflow: initial !important;
}

.carousel .slide img {
    object-fit: cover !important;
}

.c-compare {
    --h: 9;
    --m: 0rem 0;
    --w: 16;
    --thumb-bgc: rgba(34, 32, 40, 0.464);
    --thumb-bgc-focus: hsla(0, 0%, 0%, 0.7);
    --thumb-w: 3px;
    transition: all;
    margin: var(--m);
    position: relative;
  }
  
  .c-compare::after {
    content: "";
    display: block;
    padding-bottom: calc((var(--h) / var(--w)) * 100%);
  }
  .c-compare__left,
  .c-compare__right {
    height: 100%;
    // object-fit: cover;
    position: absolute;
    // width: 100%;
    // display: flex;
    // justify-content: center;
  }
  .c-compare__left {
    clip-path: polygon(0% 0%, var(--value) 0%, var(--value) 100%, 0% 100%);
  }
  .c-compare__right {
    clip-path: polygon(100% 0%, var(--value) 0%, var(--value) 100%, 100% 100%);
  }
  .c-compare__range {
    background-color: transparent;
    box-sizing: border-box;
    font-family: inherit;
    height: 100%;
    margin: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .c-compare__range::-moz-range-thumb {
    background-color: var(--thumb-bgc);
    cursor: ew-resize;
    height: 100%;  
    width: var(--thumb-w);
  }
  .c-compare__range::-webkit-slider-thumb {
    background-color: var(--thumb-bgc);
    cursor: ew-resize;
    height: 100%;  
    width: var(--thumb-w);
  }
  .c-compare__range:focus::-webkit-slider-thumb {
    background-color: var(--thumb-bgc-focus);
    box-shadow: 0 0 0 3px var(--thumb-bgc);
  }
  .c-compare__range:focus::-moz-range-thumb {
    background-color: var(--thumb-bgc-focus);
    box-shadow: 0 0 0 3px var(--thumb-bgc);
  }
  .c-compare__range::-moz-range-track {
    background: transparent;
    background-size: 100%;
    box-sizing: border-box;
  }
  .c-compare__range::-webkit-slider-runnable-track {
    background: transparent;
    background-size: 100%;
    box-sizing: border-box;
    height: 100%;
  }
  .c-compare__range,
  .c-compare__range::-webkit-slider-runnable-track,
  .c-compare__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
  }
  .video-carousel{
    position: relative !important;
  }
  
  .video-carousel .carousel .slider-wrapper.axis-horizontal .slider, .slider-wrapper, .video-carousel ul{
    position: relative !important;
  }
  

@media screen and (min-width: 1440px) {
    .competency_img {
        width: 60px;
    }

    .competency_section_height {
        min-height: 228px;
    }

    .compentency_name {
        font-size: 32px;
    }

    .watch_video {
        font-size: 15px;
    }

    .carousel .control-next.control-arrow {
        right: 28% !important;
        top: 90.5% !important;
    }

    .carousel_slide_number {
        right: 32% !important;
    }

    .carousel .control-prev.control-arrow {
        left: 59.5% !important;
        top: 90.5% !important;
    }

    .carousel .control-arrow,
    .carousel.carousel-slider .control-arrow {
        bottom: 5% !important
    }

}

@media screen and (max-width: 1024px) {
    .carousel .control-prev.control-arrow {
        top: 79% !important;
        left: 38% !important;
    }

    .carousel .control-next.control-arrow {
        top: 79% !important;
        right: 37% !important;
    }

    .carousel_slide_number {
        right: 46% !important;
        bottom: 11% !important;
    }

    .carousel .control-dots {
        width: 100% !important;
        margin: auto !important;
        right: auto !important;
    }

}

@media screen and (max-width: 992px) {
    .carousel .control-prev.control-arrow {
        top: 79% !important;
        left: 36% !important;
    }

    .carousel .control-next.control-arrow {
        top: 79% !important;
        right: 35% !important;
    }

    .carousel_slide_number {
        right: 45% !important;
        bottom: 11% !important;
    }

    .carousel .control-dots {
        width: 100% !important;
        margin: auto !important;
        right: auto !important;
    }

}

@media screen and (max-width: 768px) {
    .carousel .control-prev.control-arrow {
        top: 79% !important;
        left: 35% !important;
    }

    .carousel .control-next.control-arrow {
        top: 79% !important;
        right: 35% !important;
    }

    .carousel_slide_number {
        right: 45% !important;
        bottom: 11% !important;
    }

    .carousel .control-dots {
        width: 100% !important;
        margin: auto !important;
        right: auto !important;
    }

    .banner_head {
        font-size: 2rem !important;
    }
}

@media screen and (max-width: 480px) {
    .carousel .control-prev.control-arrow {
        top: 79% !important;
        left: 26% !important;
    }

    .carousel .control-next.control-arrow {
        top: 79% !important;
        right: 28% !important;
    }

    .carousel_slide_number {
        right: 42% !important;
        bottom: 11% !important;
    }

    .carousel .control-dots {
        width: 100% !important;
        margin: auto !important;
        right: auto !important;
    }

}

@media screen and (max-width: 290px) {
    .carousel .control-prev.control-arrow {
        top: 79% !important;
        left: 15% !important;
    }

    .carousel .control-next.control-arrow {
        top: 79% !important;
        right: 21% !important;
    }

    .carousel_slide_number {
        right: 40% !important;
        bottom: 11% !important;
    }

    .carousel .control-dots {
        width: 100% !important;
        margin: auto !important;
        right: auto !important;
    }

}